import gsap from 'gsap';
import $ from '../core/Dom';

export default el => {
    const dom = $(el);
    const slides = dom.find('[data-slide]').nodes.reverse();
    const numberOfSlides = slides.length - 1;
    const navigation = dom.find('[data-navigation]');
    const buttons = navigation.find('[data-button]');
    const circles = buttons.find('circle').nodes;

    let timeline = null;
    let observer = null;
    let index = 0;
    let seeking = false;
    let currentSlide = slides[0];

    const onComplete = () => {
        // console.log('onComplete');
        timeline.restart();
        next();
    };

    const redraw = () => {
        // console.log('redraw', index);
        const newSlide = slides[0];
        $(currentSlide).removeClass('z-2').addClass('z-1').attr('tabindex', '-1');
        $(newSlide).addClass('z-2').attr('tabindex', '0');

        gsap.fromTo(newSlide, { opacity:0 }, { duration: 0.5, opacity: 1, ease: 'sine.out', onComplete: function(){
            $(currentSlide).removeClass('z-1');
            currentSlide = newSlide;
        }});

        const navigationColor = $(newSlide).hasClass('text-white') ? '#ffffff' : '#101212';
        gsap.to(navigation.get(0), { color: navigationColor, duration: 0.35 });

    };

    const updateIndex = value => {
        index = value;
        if (index > numberOfSlides) {
            index = 0;
        } else if (index < 0) {
            index = numberOfSlides;
        }
        // console.log('updateIndex', index);
        seeking = true;
        const progress = (index / (numberOfSlides + 1) + 0.01);
        timeline.progress(progress);
        setTimeout(() => {
            seeking = false;
        }, 100);
        return index;
    };

    const next = () => {
        updateIndex(index + 1);
        slides.push(slides.shift());
        redraw();
    };

    const jumpTo = e => {
        const oldIndex = index;
        const newIndex = buttons.nodes.indexOf(e.triggerTarget);
        // console.log({ oldIndex, newIndex });
        if (oldIndex !== newIndex) {
            index = updateIndex(newIndex);
            const diff = Math.max(oldIndex, index) - Math.min(oldIndex, index);
            if (index > oldIndex) {
                slides.push(...slides.splice(0, diff));
            } else {
                slides.unshift(...slides.splice(diff * -1));
            }
            redraw();
        }
    };

    const initCircles = () => {
        gsap.set(circles, { rotation: -90, drawSVG: '0%', transformOrigin: 'center center' });
        buttons.find('svg').removeClass('hidden');
        timeline = gsap.timeline({ paused: true });
        circles.forEach((circle, i) => {
            timeline.to(circle, {
                drawSVG: '100%',
                duration: 5,
                ease: 'sine.inOut',
                onStart: () => {
                    buttons.get(i).classList.remove('opacity-30');
                },
                onComplete: () => {
                    if (!seeking) {
                        if (i === circles.length - 1) {
                            onComplete();
                        } else {
                            next();
                        }
                        buttons.get(i)
                            .classList
                            .add('opacity-30');
                        gsap.fromTo(circle, {
                            drawSVG: '100%'
                        }, {
                            drawSVG: '100% 100%',
                            duration: 2,
                            ease: 'quad.in'
                        });
                    }
                }
            });
        });
    };

    const onIntersectionChange = entries => {
        if (entries.filter(entry => entry.isIntersecting).length) {
            timeline.play();
        } else {
            timeline.pause();
        }
    };

    const init = () => {
        initCircles();
        observer = new IntersectionObserver(onIntersectionChange);
        observer.observe(el);
        buttons.on('click', jumpTo);
    };

    const destroy = () => {
        buttons.off('click', jumpTo);
        if (observer) {
            observer.unobserve(el);
        }
    };

    return {
        init,
        destroy
    };
};
