import $ from '../core/Dom';
import agent from '../core/request';

export default (el, props) => {
    const dom = $(el);

    const container = dom.find('#articles');
    const filters = dom.find('[data-filter]');

    const initialState = {
        url: document.location.href,
        markup: container.html()
    };

    const onStateChanged = state => {
        console.log('onStateChanged', state);
        filters.each(filter => {
            if (filter.href === props.baseUrl) {
                $(filter).attr('aria-current', state.url === props.baseUrl ? 'page' : null);
            } else {
                $(filter).attr('aria-current', state.url.startsWith(filter.href) ? 'page' : null);
            }
        });
        container.html(state.markup);
    };

    const loadContent = url => {
        agent
            .get(url)
            .end((error, res) => {
                if (error) {
                    console.error(error);
                } else {
                    const state = {
                        url,
                        markup: $(res.text).find('#articles').html()
                    };
                    window.history.pushState(state, '', url);
                    onStateChanged(state);
                }
            });
    };

    const onFilter = e => {
        e.preventDefault();
        loadContent(e.triggerTarget.href);
    };

    const onPopState = e => {
        const state = e.state || initialState;
        onStateChanged(state);
    };

    const init = () => {
        filters.on('click', onFilter);
        window.addEventListener('popstate', onPopState);
    };

    const destroy = () => {
        filters.off('click', onFilter);
        window.removeEventListener('popstate', onPopState);
    };

    return {
        init,
        destroy
    };
};
