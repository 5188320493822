import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT } from '../lib/events';

const loadMapbox = require('bundle-loader?lazy&name=[name]!mapbox-gl');

export default (el, props) => {

    const dom = $(el);

    const { entryId, markers, apiKey, showNavigation, zoomLevel, fitToBounds, centerOnBounds, svg } = props;

    const areaBounds = [
        [
            10.742623378993414,
            59.914047676698885
        ],
        [
            10.741330889284342,
            59.91308441676597
        ],
        [
            10.74038306349658,
            59.912151367041844
        ],
        [
            10.743131758279333,
            59.91145156253859
        ],
        [
            10.744863694490164,
            59.913218324527975
        ],
        [
            10.743657370760985,
            59.91369347674433
        ],
        [
            10.742623378993414,
            59.914047676698885
        ]
    ];

    let observer = null;
    let Mapbox = null;
    let openPopup = null;
    let map = null;
    let monogram = null;

    const popupModeQuery = window.matchMedia('(orientation: landscape)');

    const onPopupModeChange = e => {
        if (openPopup) {
            const content = openPopup._content;
            const marker = openPopup._marker;
            const markerElement = marker.getElement();
            if (e.matches) {
                content.style.setProperty('--popupOffsetX', `${(markerElement.offsetWidth / 2) + 16}px`);
                content.style.setProperty('--popupOffsetY', '0');
                map.panTo(marker.getLngLat(), { duration: 500 });
            } else {
                content.style.setProperty('--popupOffsetX', '0');
                content.style.setProperty('--popupOffsetY', `${(markerElement.offsetHeight / 2) + 16}px`);

                const px = map.project(marker.getLngLat());
                const element = openPopup.getElement();
                const rect = element.getBoundingClientRect();
                const mapRect = el.getBoundingClientRect();
                const offset = (mapRect.height / 2) - (rect.height + 37);
                px.y -= offset;
                map.panTo(map.unproject(px));
            }
        }
    };

    const toggleMarkerState = (popup, state) => {
        const marker = popup._marker.getElement();
        marker.classList.toggle('active', state);
    };

    const onPopupOpen = e => {
        const { target } = e;
        openPopup = target;
        if (openPopup) {
            console.log(openPopup);
            toggleMarkerState(openPopup, true);
            onPopupModeChange(popupModeQuery);
        }
    };

    const onPopupClose = () => {
        toggleMarkerState(openPopup, false);
        openPopup = null;
    };

    const onBodyKeyUp = e => {
        if (e.key === 'Escape' && openPopup !== null) {
            toggleMarkerState(openPopup, false);
            openPopup.remove();
            openPopup = null;
        }
    };

    const setOutlineOpacity = opacity => {
        try {
            map.setPaintProperty('outline', 'fill-opacity', opacity);
        } catch (e) {
            console.log(e);
        }
    };

    const onZoomChanged = () => {
        const zoom = Math.round(map.getZoom());
        console.log('onZoomChanged', zoom);
        if (zoom < 14) {
            setOutlineOpacity(0);
        } else if (zoom < 16) {
            setOutlineOpacity(0.75);
        } else {
            setOutlineOpacity(0);
        }
        if (zoom > 15) {
            markers.forEach(m => m.marker.removeClassName('hidden'));
            monogram.addClassName('hidden');
        } else {
            markers.forEach(m => m.marker.addClassName('hidden'));
            monogram.removeClassName('hidden');
        }
    };

    const onZoomToDistrict = () => {
        console.log('onZoomToDistrict');
        const bounds = new Mapbox.LngLatBounds();
        areaBounds.forEach(b => bounds.extend(b));
        map.fitBounds(bounds);
    };

    const onMapStyleLoaded = () => {
        map.off('style.load', onMapStyleLoaded);
        map.addSource('district', {
            'type': 'geojson',
            'data': {
                "type": "Feature",
                "properties": {},
                "geometry": {
                    "coordinates": [areaBounds],
                    "type": "Polygon"
                }
            }
        });

        map.addLayer({
            id: 'outline',
            type: 'fill',
            source: 'district',
            paint: {
                'fill-color': '#52FF00',
                'fill-opacity': 0
            }
        });
    };

    const createMapbox = () => {
        Mapbox.accessToken = apiKey;
        map = new Mapbox.Map({
            container: dom.find('[data-map-container]').get(0),
            scrollZoom: false,
            style: 'mapbox://styles/promenaden/cm1khnnxo00nr01qpbgkdaa8l',
            center: [10.7426331, 59.9127387],
            zoom: zoomLevel,
            minZoom: 4,
            maxZoom: 22,
            attributionControl: false,
            cooperativeGestures: true,
            logoPosition: 'top-left'
        }).addControl(new Mapbox.AttributionControl({
            compact: true
        }), 'top-right');

        map.on('style.load', onMapStyleLoaded);

        if (showNavigation) {
            const geoLocateControl = new Mapbox.GeolocateControl({
                positionOptions: {
                    enableHighAccuracy: true
                },
                trackUserLocation: true
            });
            geoLocateControl.on('trackuserlocationend', onZoomToDistrict);
            const navigationControl = new Mapbox.NavigationControl({
                visualizePitch: false,
                showCompass: false
            });
            map.addControl(geoLocateControl, 'bottom-left').addControl(navigationControl, 'bottom-right');
        }

        monogram = new Mapbox.Marker({ element: $(`<div>${svg}</div>`).get(0) }).setLngLat([10.7426331, 59.9127387]).addTo(map);
        monogram.getElement().addEventListener('click', onZoomToDistrict);

        const bounds = new Mapbox.LngLatBounds();

        markers.forEach(marker => {
            const mapMarker = new Mapbox.Marker({
                element: $(`<div class="hover:z-2 [&.active]:z-1"><div class="bg-ui-bg-tertiary text-white rounded-4px map-shadow text-detail-medium !leading-1 transition-color duration-350 ease-out hover:bg-green hover:text-black p-8 m:px-16 m:py-12 select-none [.active_&]:bg-white [.active_&]:text-black" data-filterable="${marker.categories}">${marker.title}</div></div>`).get(0)
            })
                .setLngLat([marker.location.lng, marker.location.lat])
                .addTo(map);

            bounds.extend([marker.location.lng, marker.location.lat]);

            const popup = new Mapbox.Popup({
                maxWidth: 342,
                anchor: 'center',
                closeButton: false,
                closeOnClick: markers.length > 1,
                closeOnMove: false,
                focusAfterOpen: false
            }).setHTML(marker.popupContent);

            mapMarker.setPopup(popup);
            popup.on('open', onPopupOpen);
            popup.on('close', onPopupClose);

            marker.marker = mapMarker;

            if (entryId === marker.id) {
                popup.addTo(map);
                map.setCenter([marker.location.lng, marker.location.lat]);
            }
        });

        if (fitToBounds) {
            map.fitBounds(bounds, { animate: false, padding: 75 });
        } else if (centerOnBounds) {
            map.setCenter(bounds.getCenter());
        }

        map.on('zoomend', onZoomChanged);
        onZoomChanged();

        popupModeQuery.addEventListener('change', onPopupModeChange);
    };

    const init = () => {
        loadMapbox(mapboxgl => {
            Mapbox = mapboxgl;
            createMapbox();
            document.body.addEventListener('keyup', onBodyKeyUp);
            Dispatch.emit(COMPONENT_INIT);
        });
    };

    const destroy = () => {
        document.body.removeEventListener('keyup', onBodyKeyUp);
        popupModeQuery.removeEventListener('change', onPopupModeChange);
    };

    return {
        init,
        destroy
    };
};
